/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'

export default function data(props) {
  const navigate = useNavigate();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const Author = ({ name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="high">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
    function callbackRequest(id){
      navigate(`/updatetime/${id}`)
    }  
  const Statusdata = ({val}) => {
    if(val) {
              return(
                        <MDBox ml={-1}>
                          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
                        </MDBox>
                        )
    }
    else{
      return(<MDBox ml={-1}>
              <MDBadge badgeContent="Inactive" color="dark" variant="gradient" size="sm" />
          </MDBox>)
    }
  }
  const cookies = new Cookie();
  const colm = []
  if ([1,6].includes(cookies.get('role_type')) === true){
    colm.push(
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Time", accessor: "time", width: "0%", align: "center" },
      { Header: "Start Hours", accessor: "hours", width: "20%", align: "center" },
      { Header: "Start Minutes", accessor: "minutes", align: "center" },
      { Header: "Morning/Evening", accessor: "morning_eveing", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    )
  }
  else{
    colm.push(
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Time", accessor: "time", width: "0%", align: "center" },
      { Header: "Start Hours", accessor: "hours", width: "20%", align: "center" },
      { Header: "Start Minutes", accessor: "minutes", align: "center" },
      { Header: "Morning/Evening", accessor: "morning_eveing", align: "center" },
    )
  }

  var dict = []; // Create an empty array
  if (props.data) {
    const row_datas = props.data.map((data,index) =>
                
                  dict.push(
                        {
                          srn: <Author name={index+1}  />,
                          time: <Author name={data.time_condition}  />,
                          hours: <Author name={data.hours}  />,
                          minutes: <Author name={data.minutes}  />,
                          morning_eveing: <Author name={data.AM_PM}  />,
                          action: (
                            <MDTypography component="a" variant="caption" color="text" fontWeight="medium" onClick={(e) => callbackRequest(data.id)}>
                              Edit
                            </MDTypography>
                          )
                        }
                      )
                    )
                      }
                      
  return {
    columns: colm,
    rows: dict
  };
}
