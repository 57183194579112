/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Createusers page components
import Header from "layouts/createusers/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import MDAlert from "components/MDAlert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Data
import profilesListData from "layouts/profile/data/profilesListData";
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './base.css'

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// Images
// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/calenderdashbaord/data/authorsTableData";


function CalenderDashbaord() {

  const cookies = new Cookie();
  const navigate = useNavigate();
  
  console.log(cookies.get('token'))
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"

    }
  const [allusersdata, setAllusersdata] = useState([]);
  const [allusers, setAllusers] = useState(false);
  const [destination ,setDestination] = useState("");
  const [firstname ,setFirstname] = useState("");
  const [selectdate ,setSelectdate] = useState("");
  const [botonoff ,setBotonoff] = useState(true);
  const [holidayreason ,setHolidayreason] = useState("");
  const [alltyperoles ,setAlltyperoles] = useState([]);
  console.log('cdsvdscds',botonoff,selectdate)
  const notify = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }


  function callbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'post',
      url:base_url+'/api/auth/v1/api/calender/dashboard/',
      data: {
        'date' : selectdate,
        'name' : firstname,
        'reason' : holidayreason,
        'designation' : destination,
        'on_off' : botonoff
      },
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          {notify("Record Created Successfully !!")}
        }
        else{
          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
      {notify("Please Check Required fileds ")}

      console.log(error)
    });
  }

  useEffect(()=>{
    const response = axios({
      method: 'get',
      url:base_url+'/api/auth/v1/api/calender/dashboard/',
      headers : {'Authorization': 'Token '+cookies.get('token')}
    })
    .then(function (response) {
      console.log(response,'responseresponseresponseresponse')
        if(response.data){
          setAllusersdata(response.data)
        }
  })
  .catch(function (error) {
    console.log('error')
  }); 

  const response1 = axios({
    method: 'get',
    url:base_url+'/api/auth/v1/api/users/roles/',
    headers : {'Authorization': 'Token '+cookies.get('token')}
  })
  .then(function (response) {
      if(response){
        console.log(response)
        setAlltyperoles(response.data)
      }
    })
    .catch(function (error) {
      console.log('error')
    }); 
        
  }, [])
  const handleChange = (event) => {
    setBotonoff(event.target.checked);
  };
  const row_datas = alltyperoles.map((data,index) =>{
            return(
                  <option value={data.val} key={index}>{data.val}</option>
            )
        })
  console.log(allusersdata)
  const { columns, rows } = authorsTableData({'data' : allusersdata});

  const show_data = () => {
    if (allusers === false) {
      return(
        <div>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                Holiday Details
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                // canSearch={true}
                isSorted={true}
                entriesPerPage={true}
                showTotalEntries={true}
                noEndBorder={false}
                pagination={true}
              />
            </MDBox>
          </Card>   
        </div>
      )
    }
    else{
      return(
        <div >

            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={5}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                Calender Dashboard
              </MDTypography>
            </MDBox>
          <MDBox>
            <Grid container spacing={1}>
              
              <Grid item xs={12} md={6} lg={5} px={1}>
              <FormControl fullWidth sx={{ mt: 3 ,mx:1}} required>
                    <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start"></InputAdornment>}
                      label="Name"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 3,mx:1 }} required>
                    <InputLabel htmlFor="outlined-adornment-amount">Destignation</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start"></InputAdornment>}
                      label="Destignation"
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 3,mx:1 }} required>
                    <InputLabel htmlFor="outlined-adornment-amount">Holiday Reason</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start" ></InputAdornment>}
                      label="Holiday Reason"
                      value={holidayreason}
                      onChange={(e) => setHolidayreason(e.target.value)}

                    />
                  </FormControl>
                  <FormGroup sx={{ py: 5 }} >
                    <FormControlLabel  label="Bot On/Off" required  control={<Switch  checked={botonoff}
      inputProps={{ 'aria-label': 'controlled' }} onChange={handleChange}/>} />
                  </FormGroup>
                  



              </Grid>
              <Grid item xs={12} md={6} lg={7} >
              <MDBox   display="flex" className={"react-calendar"}>
                <Calendar defaultActiveStartDate={new Date()} onChange={(value, event) => setSelectdate(value)} />
              </MDBox>
              {/* <Calendar onChange={onChange} value={value} /> */}

                {/* <OrdersOverview /> */}
                {/* <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>
                  <MDBox  bgColor="success" key="first_name" display="flex" py={1} pr={2} px={10} justifyContent='center' alignItems='center' textAlign='center'>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                    First Name : &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text" >
                      <MDInput type="text" value={firstname} variant="outlined" size="small"  onChange={(e) => setFirstname(e.target.value)}/>
                    </MDTypography>
                  </MDBox> 
                        
                  <MDBox bgColor="success" key="last_name" display="flex" py={1} pr={2} px={3} justifyContent='center' alignItems='center' textAlign='center'>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" px={0}>
                    Last Name : &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text" >
                      <MDInput type="text" value={lastname} size="small"  onChange={(e) => setLastname(e.target.value)}/>
                    </MDTypography>
                  </MDBox>
                </Grid> */}

              </Grid>
            </Grid>
          </MDBox>

          <MDBox key="button" display="flex" py={5} pr={2}  justifyContent='center' alignItems='center'>
            <MDButton variant="gradient" px={1} color="info" onClick={(e) => callbackRequest(e)}>Submit</MDButton>  
          </MDBox>
      </div>
      )
    }

  }
  const button_cond = () => {
  if ([1,2,3,6].includes(cookies.get('role_type')) === true){
    return(
      <>
        <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
          <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(false)}>Holiday/Working Day Record</MDButton>
        </Grid>
        <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
          <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(true)}>Holiday/Working Day Declaration</MDButton>
        </Grid>
      </>
    )
  }
  else{
  <div>

  </div>
  }
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />

      {/* <MDBox mb={2} /> */}
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} >
            {button_cond()}
            <Grid container spacing={12} justifyContent='center' alignItems='center' textAlign='center'>
              <Grid item xs={12}>
                {show_data()}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default CalenderDashbaord;
