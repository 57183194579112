/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Createusers from "layouts/createusers";
import Cookie from 'universal-cookie'

// @mui icons
import Icon from "@mui/material/Icon";
import Updateusers from "layouts/createusers/edituser";
import Tickets from "layouts/tickets";
import Updateticket from "layouts/tickets/editticket";
import Bottime from "layouts/bottime";
import Updatebottime from "layouts/bottime/editbottime";
import CalenderDashbaord from "layouts/calenderdashbaord";
import Summary from "layouts/summary";
import Detailedreport from "layouts/detailedreport";
import Transactionreport from "layouts/transactionreport";
import Scanner from "layouts/scanner";
import QrScanner from "layouts/qrcodescanner";
import SearchFunc from "layouts/searchfunc";
import SummaryPDFReport from "layouts/summary/pdfreport";
import ChartViews from "layouts/chartsview";
const cookies = new Cookie();

const routes = [
  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    name: "Pdf Report",
    key: "pdf-report",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/summary_report",
    component: <SummaryPDFReport />,
  }
]

if ([1,2,3,4,5,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Summary",
    key: "dashboard",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/dashboard",
    component: <Summary />,
  })
}

if ([1,2,3,4,5,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Chart Reports",
    key: "chartreport",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/chartreport",
    component: <ChartViews />,
  })
}
if ([1,2,3,4,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Transaction Report",
    key: "transaction",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/transaction",
    component: <Transactionreport />,
  })
}

if ([1,2,3,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Detailed Report",
    key: "detailed",
    icon: <Icon fontSize="small">details</Icon>,
    route: "/detailed",
    component: <Detailedreport />,
  })
}

if ([1,2,3,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Calender",
    key: "calender",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/calender",
    component: <CalenderDashbaord />,
  })
}
if ([1,2,3,6].includes(cookies.get('role_type')) === true){
  routes.push({
    name: "Tickets",
    key: "updateticket",
    icon: <Icon fontSize="small">update</Icon>,
    route: "/updatetickets/:id",
    component: <Updateticket />,
  })
}
if ([1,2,3,4,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Tickets",
    key: "tickets",
    icon: <Icon fontSize="small">local_activity</Icon>,
    route: "/tickets",
    component: <Tickets />,
  })
}
if ([1].includes(cookies.get('role_type')) === true){
  routes.push({
    name: "Bot Time",
    key: "update_time",
    icon: <Icon fontSize="small">refresh</Icon>,
    route: "/updatetime/:id",
    component: <Updatebottime />,
  })
}
if ([1,2,3,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Bot Time",
    key: "time",
    icon: <Icon fontSize="small">refresh</Icon>,
    route: "/time",
    component: <Bottime />,
  })
}
if ([1,2,3,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Visitor Search",
    key: "search",
    icon: <Icon fontSize="small">explore</Icon>,
    route: "/search",
    component: <SearchFunc />,
  })
}
if ([1,2,3,4,5,6].includes(cookies.get('role_type')) === true){
  routes.push({
    type: "collapse",
    name: "Scanner",
    key: "scanner",
    icon: <Icon fontSize="small">scanner</Icon>,
    route: "/scanner",
    component: <Scanner />,
  })
}
if ([1,2,3,4,5,6].includes(cookies.get('role_type')) === true){
  routes.push({
    // type: "collapse",
    name: "QR Scanner",
    key: "qrscanner",
    icon: <Icon fontSize="small">scanner</Icon>,
    route: "/qrscanner",
    component: <QrScanner />,
  })
}
// if ([1,2,3,4,5,6].includes(cookies.get('role_type')) === true){
//   routes.push(   {
//       type: "collapse",
//       name: "Profile",
//       key: "profile",
//       icon: <Icon fontSize="small">person</Icon>,
//       route: "/profile",
//       component: <Profile />,
//   },
// )
// }
if ([1].includes(cookies.get('role_type')) === true){
  routes.push(  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">create</Icon>,
    route: "/users",
    component: <Createusers />,
  })
}
if ([1].includes(cookies.get('role_type')) === true){
  routes.push(  {
    name: "Create Users",
    key: "updateusers",
    icon: <Icon fontSize="small">update</Icon>,
    route: "/users/:id",
    component: <Updateusers />,
  })
}
routes.push(
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
}
  )
  
export default routes;
