/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
export default function data(props) {
  const navigate = useNavigate();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const Author = ({ name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
    function callbackRequest(id){
      navigate(`/updatetickets/${id}`)
    }  
  const Statusdata = ({val}) => {
    if(val == 'Done') {
              return(
                        <MDBox ml={-1}>
                          <MDBadge badgeContent="Completed" color="success" variant="gradient" size="sm" />
                        </MDBox>
                        )
    }
    else{
      return(<MDBox ml={-1}>
              <MDBadge badgeContent="Not Completed" color="dark" variant="gradient" size="sm" />
          </MDBox>)
    }
  }
  var dict = []; // Create an empty array

  if (props.data) {
    const row_datas = props.data.map((data,index) =>
                  dict.push(
                        {
                          srn: <Author name={index+1}  />,
                          time_stmape : <Author name={data['Time Stamp']}  />,
                          booking_date : <Author name={data['Booking Date']}  />,
                          total_number_of_adults : <Author name={data['Total Number Of Adult Tickets']}  />,
                          revenue_by_adults : <Author name={data['Revenue By Adult Tickets']}  />,
                          
                          total_number_of_Childs : <Author name={data['Total Number Of Child Tickets']}  />,
                          revenue_by_Childs : <Author name={data['Revenue By Child Tickets']}  />,

                          total_number_of_Foreigners : <Author name={data['Total Number Of Foreigner Tickets']}  />,
                          revenue_by_Foreigners : <Author name={data['Revenue By Foreigner Tickets']}  />,

                          total_number_of_Cameras : <Author name={data['Total Number Of Camera Tickets']}  />,
                          revenue_by_Cameras : <Author name={data['Revenue By Camera Tickets']}  />,

                          total_number_of_H_END_Cameras : <Author name={data['Total Number Of H-END Camera Tickets']}  />,
                          revenue_by_H_END_Cameras : <Author name={data['Revenue By H-END Camera Tickets']}  />,

                          total_tickets : <Author name={data['Total_Tickets']}  />,
                          total_amt_with_serverice_charge : <Author name={data['Total Amounts (incl. Service charge)']}  />,
                          total_amt_with_out_serverice_charge : <Author name={data['Total Amount Without Service Charge']}  />,
                          transaction_id : <Author name={data['Transaction Id']}  />,
                          status: <Statusdata val={data['Payment Status']}  />,
                        }
                      )
                    )
                      }

                      
  return {
    columns: [
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Time Stamp", accessor: "time_stmape", width: "0%", align: "center" },
      { Header: "Booking Date", accessor: "booking_date", width: "0%", align: "center" },
      { Header: "Total Number Of Adult Tickets", accessor: "total_number_of_adults", align: "center" },
      { Header: "Revenue By Adult Tickets", accessor: "revenue_by_adults", align: "center" },

      { Header: "Total Number Of Child Tickets", accessor: "total_number_of_Childs", align: "center" },
      { Header: "Revenue By Child Tickets", accessor: "revenue_by_Childs", align: "center" },

      { Header: "Total Number Of Foreigner Tickets", accessor: "total_number_of_Foreigners", align: "center" },
      { Header: "Revenue By Foreigner Tickets", accessor: "revenue_by_Foreigners", align: "center" },

      { Header: "Total Number Of Camera Tickets", accessor: "total_number_of_Cameras", align: "center" },
      { Header: "Revenue By Camera Tickets", accessor: "revenue_by_Cameras", align: "center" },

      { Header: "Total Number Of H-END Camera Tickets", accessor: "total_number_of_H_END_Cameras", align: "center" },
      { Header: "Revenue By H-END Camera Tickets", accessor: "revenue_by_H_END_Cameras", align: "center" },


      { Header: "Total Tickets", accessor: "total_tickets", align: "center" },
      { Header: "Total Amounts (incl. Service charge)", accessor: "total_amt_with_serverice_charge", align: "center" },
      { Header: "Total Amount Without Service Charge", accessor: "total_amt_with_out_serverice_charge", align: "center" },
      { Header: "Transaction Id", accessor: "transaction_id", align: "center" },
      { Header: "Payment Status", accessor: "status", align: "center" },
    ],
    rows: dict
  };
}
