/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Createusers page components
import Header from "layouts/createusers/components/Header";
import MDAlert from "components/MDAlert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
// Data
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import 'reactjs-popup/dist/index.css';
import { HeartSwitch } from '@anatoliygatt/heart-switch';

import { useParams ,useLocation} from "react-router-dom";

import authorsTableData from "layouts/createusers/data/authorsTableData";


function Updateusers() {

  const cookies = new Cookie();
  const navigate = useNavigate();

  console.log(cookies.get('token'))
  if (cookies.get('token') === undefined){
            window.location.href = "/authentication/sign-in"

  }

  const [allusersdata, setAllusersdata] = useState([]);
  const [allusers, setAllusers] = useState(true);
  const [firstname ,setFirstname] = useState("");
  const [lastname ,setLastname] = useState("");
  const [email ,setEmail] = useState("");
  const [roletype ,setRoletype] = useState("");
  const [alltyperoles ,setAlltyperoles] = useState([]);
  const [checked, setChecked] = useState(false);

  const { id } = useParams()
  
  const notify = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }
  const danger_notify = (msg) => {
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }

  function callbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'patch',
      url:base_url+'/api/auth/v1/api/users/',
      data: {
        'id' : id,
        'first_name' : firstname,
        'last_name' : lastname,
        'user_type' : roletype,
        "is_active" : checked
      },
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          {notify("USer Updated Successfully !!")}
        }
        else{
          {danger_notify("User Does Not Exists")}

          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
          {danger_notify("User Does Not Exists")}
          console.log(error)
    });
  }

  useEffect(()=>{
    const response = axios({
      method: 'get',
      url:base_url+`/api/auth/v1/api/user/${id}/`,
      headers : {'Authorization': 'Token '+cookies.get('token')}
    })
    .then(function (response) {
        if(response.data){
          setFirstname(response.data.first_name);
          setLastname(response.data.last_name)
          setEmail(response.data.email)
          setRoletype(response.data.roles)
          setChecked(response.data.is_active)
        }
  })
  .catch(function (error) {
    {danger_notify("User Does Not Exists")}
    navigate('/users')

  }); 

  const response1 = axios({
    method: 'get',
    url:base_url+'/api/auth/v1/api/users/roles/',
    headers : {'Authorization': 'Token '+cookies.get('token')}
  })
  .then(function (response) {
      if(response){
        console.log(response)
        setAlltyperoles(response.data)
      }
    })
    .catch(function (error) {
      console.log('error')
    }); 
        
  }, [])

  const row_datas = alltyperoles.map((data,index) =>{
            return(
                  <option key={data.val} >{data.val}</option>
            )
        })

  const { columns, rows } = authorsTableData({'data' : allusersdata});
  const show_data = () => {
    if (allusers === false) {
      return(
        <div>
  
        </div>
      )
    }
    else{
      return(
        <div >
          <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={5}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                Update User
              </MDTypography>
            </MDBox>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>
            <MDBox key="first_name" display="flex" py={1} pr={2} px={10} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
              First Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="text" value={firstname} variant="outlined" size="small"  onChange={(e) => setFirstname(e.target.value)}/>
              </MDTypography>
            </MDBox> 
                  
            <MDBox key="last_name" display="flex" py={1} pr={2} px={3} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" px={0}>
              Last Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="text" value={lastname} size="small"  onChange={(e) => setLastname(e.target.value)}/>
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            <MDBox key="last_name" display="flex" py={1} pr={0} px={9} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={0}>
              Email Id : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="email" value={email}  size="small"   />
              </MDTypography>
            </MDBox>
            <MDBox key="last_name" display="flex" py={1} pr={2} px={6} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1} justifyContent='left' alignItems='left' textAlign='left'>
              Role : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <select onChange={(e) => setRoletype(e.target.value)}  style={{color: "black",width:"160px",fontSize : "18px",border : "solid gray 2px",height : "36px",borderRadius : "10px"}}>
                  {/* <option value="Select">Select</option> */}
                  {row_datas}
                </select>
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center' pt={5}>

              <MDBox key="last_name" display="flex" py={1} pr={0} px={9} justifyContent='center' alignItems='center' textAlign='center'>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={0}>
                Active : &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" >
                <HeartSwitch
                      size="sm"
                      inactiveTrackFillColor="#cffafe"
                      inactiveTrackStrokeColor="#22d3ee"
                      activeTrackFillColor="#06b6d4"
                      activeTrackStrokeColor="#0891b2"
                      inactiveThumbColor="#ecfeff"
                      activeThumbColor="#ecfeff"
                      checked={checked}
                      onChange={(event) => {
                        setChecked(event.target.checked);
                      }}
                    />
                  
                </MDTypography>
              </MDBox>
              
              </Grid>
          {/* <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            <MDBox key="last_name" display="flex" py={1} pr={2} px={14} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Password : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="password"  size="small"  onChange={(e) => setNewpassword1(e.target.value)} />
              </MDTypography>
            </MDBox>
            <MDBox key="last_name" display="flex" py={1} pr={2} px={-3} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Re-enter Password : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="password"  size="small"  onChange={(e) => setNewpassword2(e.target.value)} px={5}/>
              </MDTypography>
            </MDBox>
          </Grid> */}
          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            {/* <MDBox key="contact_number" display="flex" py={1} pr={2} px={6.5} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Contact Number : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text"px={0.8} >
                <MDInput type="number"  size="small"  onChange={(e) => setContactnumber(e.target.value)} />
              </MDTypography>
            </MDBox>   

            <MDBox key="location" display="flex" py={1} pr={2} px={1} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Location : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <MDInput type="text"  size="small"  onChange={(e) => setLocation1(e.target.value)}/>
              </MDTypography>
            </MDBox>  */}
          </Grid>
          <MDBox key="button" display="flex" py={5} pr={2}  justifyContent='center' alignItems='center'>
            <MDButton variant="gradient" px={1} color="info" onClick={(e) => callbackRequest(e)}>Update User</MDButton>  
          </MDBox>
      </div>
      )
    }

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />

      {/* <MDBox mb={2} /> */}
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} >
            {/* <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(false)}>All User</MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(true)}>Create New Uses</MDButton>
            </Grid> */}
            <Grid container spacing={12} justifyContent='center' alignItems='center' textAlign='center'>
              <Grid item xs={12}>
                {show_data()}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Updateusers;
