/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Createusers page components
import Header from "layouts/createusers/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import MDAlert from "components/MDAlert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
// Data
import profilesListData from "layouts/profile/data/profilesListData";
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// Images
// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tickets/data/authorsTableData";


function Tickets() {

  const cookies = new Cookie();
  const navigate = useNavigate();
  
  console.log(cookies.get('token'))
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"

    }
  const [data, setData] = useState([]);
  const [allusersdata, setAllusersdata] = useState([]);
  const [allusers, setAllusers] = useState(false);
  const [changepassdata, setChangepassdata] = useState(false);
  const [description ,setDescription] = useState("");
  const [firstname ,setFirstname] = useState("");
  const [lastname ,setLastname] = useState("");
  const [email ,setEmail] = useState("");
  const [contactnumber,setContactnumber] = useState("");
  const [location ,setLocation1] = useState("");
  const [alltyperoles ,setAlltyperoles] = useState([]);
  const [newpassword1 ,setNewpassword1] = useState("");
  const [newpassword2 ,setNewpassword2] = useState("");
  const [roletype ,setRoletype] = useState("");

  const notify = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }


  function callbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'post',
      url:base_url+'/api/auth/v1/api/users/',
      data: {
        'first_name' : firstname,
        'last_name' : lastname,
        'email' : email,
        'user_type' : roletype,
        'password' : newpassword2
      },
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          {notify("USer Created Successfully !!")}
          setAllusers(false);

          setFirstname('');
          setLastname('');
          setEmail('');
          setRoletype('');
          setNewpassword1('');
          setNewpassword2('');

          const response = axios({
            method: 'get',
            url:base_url+'/api/auth/v1/api/allusers/',
            headers : {'Authorization': 'Token '+cookies.get('token')}
          })
          .then(function (response) {
              if(response.data){
                setAllusersdata(response.data)
              }
        })
        .catch(function (error) {
          console.log('error')
        }); 
          // setData(response.data);
          // setDescription(response.data.description);
          // setLocation1(response.data.location);
          // setContactnumber(response.data.contact_number);
          // setFirstname(response.data.first_name);
          // setLastname(response.data.last_name);
        }
        else{
          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  useEffect(()=>{
    const response = axios({
      method: 'get',
      url:base_url+'/api/auth/v1/api/tickets/',
      headers : {'Authorization': 'Token '+cookies.get('token')}
    })
    .then(function (response) {
        if(response.data){
          console.log('ticket data',response)
          setAllusersdata(response.data)
        }
  })
  .catch(function (error) {
    console.log('error')
  }); 

  const response1 = axios({
    method: 'get',
    url:base_url+'/api/auth/v1/api/users/roles/',
    headers : {'Authorization': 'Token '+cookies.get('token')}
  })
  .then(function (response) {
      if(response){
        console.log(response)
        setAlltyperoles(response.data)
      }
    })
    .catch(function (error) {
      console.log('error')
    }); 
        
  }, [])

  const row_datas = alltyperoles.map((data,index) =>{
            return(
                  <option value={data.val} key={index}>{data.val}</option>
            )
        })

  const { columns, rows } = authorsTableData({'data' : allusersdata});

  const show_data = () => {
    if (allusers === false) {
      return(
        <div>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
              CHATBOT TICKETS
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                entriesPerPage={true}
                showTotalEntries={true}
                noEndBorder
              />
            </MDBox>
          </Card>   
        </div>
      )
    }
    else{
      return(
        <div >
          <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={5}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                Create New User
              </MDTypography>
            </MDBox>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>
            <MDBox key="first_name" display="flex" py={1} pr={2} px={10} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
              First Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="text" value={firstname} variant="outlined" size="small"  onChange={(e) => setFirstname(e.target.value)}/>
              </MDTypography>
            </MDBox> 
                  
            <MDBox key="last_name" display="flex" py={1} pr={2} px={3} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" px={0}>
              Last Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="text" value={lastname} size="small"  onChange={(e) => setLastname(e.target.value)}/>
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            <MDBox key="last_name" display="flex" py={1} pr={0} px={9} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={0}>
              Email Id : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="email"  size="small"  onChange={(e) => setEmail(e.target.value)} />
              </MDTypography>
            </MDBox>
            <MDBox key="last_name" display="flex" py={1} pr={2} px={6} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1} justifyContent='left' alignItems='left' textAlign='left'>
              Role : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <select onChange={(e) => setRoletype(e.target.value)}  style={{color: "black",width:"160px",fontSize : "18px",border : "solid gray 2px",height : "36px",borderRadius : "10px"}}>
                  {/* <option value="Select">Select</option> */}
                  {row_datas}
                </select>
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            <MDBox key="last_name" display="flex" py={1} pr={2} px={14} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Password : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="password"  size="small"  onChange={(e) => setNewpassword1(e.target.value)} />
              </MDTypography>
            </MDBox>
            <MDBox key="last_name" display="flex" py={1} pr={2} px={-3} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Re-enter Password : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" >
                <MDInput type="password"  size="small"  onChange={(e) => setNewpassword2(e.target.value)} px={5}/>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={1} justifyContent='center' alignItems='center' textAlign='center'>

            {/* <MDBox key="contact_number" display="flex" py={1} pr={2} px={6.5} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Contact Number : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text"px={0.8} >
                <MDInput type="number"  size="small"  onChange={(e) => setContactnumber(e.target.value)} />
              </MDTypography>
            </MDBox>   

            <MDBox key="location" display="flex" py={1} pr={2} px={1} justifyContent='center' alignItems='center' textAlign='center'>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
              Location : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <MDInput type="text"  size="small"  onChange={(e) => setLocation1(e.target.value)}/>
              </MDTypography>
            </MDBox>  */}
          </Grid>
          <MDBox key="button" display="flex" py={1} pr={2}  justifyContent='center' alignItems='center'>
            <MDButton variant="gradient" px={1} color="info" onClick={(e) => callbackRequest(e)}>Create User</MDButton>  
          </MDBox>
      </div>
      )
    }

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />

      {/* <MDBox mb={2} /> */}
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} >
            {/* <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(false)}>All User</MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(true)}>Create New Uses</MDButton>
            </Grid> */}
            <Grid container spacing={12} justifyContent='center' alignItems='center' textAlign='center'>
              <Grid item xs={12}>
                {show_data()}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Tickets;
