/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'

function Basic() {
  // const history = useNavigate();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass1, setPass] = useState("");
  const [message1, setMessage1] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberMe1, setRememberMe1] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  useEffect(()=>{
    const cookies = new Cookie();
    // cookies.set('token',response.data.token , { path: '/' });

    if (cookies.get('token') === undefined){
      navigate('/authentication/sign-in')
    }
    else{
      navigate('/dashboard')
  }
  }, [])

  function callbackRequest(e){
    e.preventDefault();

    let formField = new FormData()
    formField.append('username',email)
    formField.append('password',pass1)
    // formField.append('email',email)
    console.log(formField)
    const response = axios({
      method: 'post',
      url:base_url+'/api/auth/v1/api/token/',
      data: {
        'username' : email,
        'password' : pass1
      }
    })
    .then(function (response) {
        console.log("Enter tru conditions");
        console.log(response.data);
        console.log(response.data.access);
        if(response.data.access){
          // const [cookies, setCookie] = cookie(['access_token', 'refresh_token'])
          // let expires = new Date()
          // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
          // console.log(cookie.set('access_token', "response.data.access_token"))
          const cookies = new Cookie();
          cookies.set('token',response.data.access , { path: '/'  ,maxAge: 14 * 60 * 60,
          sameSite: true},);

          cookies.set('user_type','individual' , { path: '/'  ,maxAge: 14 * 60 * 60,
          sameSite: true},);

          navigate("/profile");
        }
        else{
          setMessage1("Please enter correct username or password");
          window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
        }
    })
    .catch(function (error) {
          setMessage1("Please enter correct username or password");
    });
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography
            color='error'
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {message1}
          </MDTypography>
            
            {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Username" fullWidth required onChange={(e) => setEmail(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth required onChange={(e) => setPass(e.target.value)}/>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth
              onClick={(e) => callbackRequest(e)}
              >
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
