/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import summary1 from "assets/images/custom_images/zoologo.jpg";

// Authentication layout components
import BasicLayout from "layouts/scanner/components/BasicLayout";
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import { ToastContainer } from "react-toastify";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

// class Scanner extends React.Component {
  function Scanner() {
   
  // const history = useNavigate();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass1, setPass] = useState("");
  const [defaultdata, setDefaultdata] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [inputticketnumber, setInputticketnumber] = useState();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [dense, setDense] = React.useState(false);
  const [ticketnotavailable, setTicketnotavailable] = React.useState(false);
  const [totalamt, setTotalamt] = useState("0");
  const [totaltickets, setTotaltickets] = useState("0");
  const [totaladults, setTotaladults] = useState("0");
  const [totalchild, setTotalchild] = useState("0");
  const [totalfor, setTotalfor] = useState("0");
  const [totalacamera, setTotalacamera] = useState("0");
  const [totalhighendcamera, setTotalhighendcamera] = useState("0");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentstate, setCurrentstate] = useState(true);
  const [colorver, setColorver] = useState("blue");

  const cookies = new Cookie();
  useEffect(()=>{
    if (cookies.get('token') === undefined){
      window.location.href = "/authentication/sign-in"
    }
    
    const response = axios({
      method: 'get',
      url:base_url+'/api/auth/v1/api/scanner/',
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response.data){
          setTotaltickets(response.data.total_tickets)
          setTotaladults(response.data.adults_ticket)
          setTotalchild(response.data.childs_ticket)
          setTotalfor(response.data.foreigners_ticket)
          setTotalacamera(response.data.camera_100)
          setTotalhighendcamera(response.data.high_end_500)
        }
        
    })
    .catch(function (error) {
          // setMessage1("Please enter correct username or password");
    });


  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log(data.get('ticket_number')); // Reference by form input's `name` tag
    const ticket_number = data.get('ticket_number')
    if (ticket_number.length){
      const response = axios({
        method: 'post',
        url:base_url+'/api/auth/v1/api/scanner/',
        headers : {'Authorization': 'Token '+cookies.get('token')},
        data : {
          ticket_number : ticket_number
        }
      })
      .then(function (response) {
          if(response.data){
            console.log(response.data)
            setDefaultdata(response.data)
            setCurrentstate(false);
            setInputticketnumber('')
            setTicketnotavailable(false)
            setColorver('blue');
            if(response.data.verification_date_time){
              setColorver('red');
              setInputticketnumber('')
            }
            if (response.data.cond === 'NONE'){
                setTicketnotavailable(true)
                setInputticketnumber('')
              }
            setInputticketnumber('')
            // setTotaltickets(response.data.total_tickets)
            // setTotaladults(response.data.adults_ticket)
            // setTotalchild(response.data.childs_ticket)
            // setTotalfor(response.data.foreigners_ticket)
            // setTotalacamera(response.data.camera_100)
            // setTotalhighendcamera(response.data.high_end_500)
          }
          
      })
      .catch(function (error) {
        setTicketnotavailable(true)
        setInputticketnumber('')
        setCurrentstate(false);
            // setMessage1("Please enter correct username or password");
      });
  
    }
    // else if(ticket_number.length > 10){
    //   setInputticketnumber('')
    // }
    // fetch('/api/form-submit-url', {
    //   method: 'POST',
    //   body: data,
    // });
  }

  const alert_data = () =>{
    if(defaultdata.verification_date_time){
      return(
        <MDBox m={1.5} borderBottom={2} bgcolor={'error'} textAlign={"center"} justifyContent='center' alignItems='center'>
          <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',backgroundColor:'red',color:'white'}}>
            THIS TICKET ALREADY VERIFIED
          </MDTypography>
        </MDBox>
      )
    }
    else if(ticketnotavailable){
      return(
        <MDBox m={1.5} borderBottom={2} bgcolor={'error'} textAlign={"center"} justifyContent='center' alignItems='center'>
          <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',backgroundColor:'red',color:'white',width:'100%'}}>
            THIS TICKET IS NOT AVAILABLE
          </MDTypography>
        </MDBox>
      )
    }
    else{
      return(
        <>
        </>
      )
    }
  }
  const verified_at_data = () =>{
    
    if(defaultdata.verification_date_time){
      return(
        <MDBox m={0.5}  textAlign={"left"} justifyContent='left' alignItems='left'>
          <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px'}}>
            TICKET VERIFIED AT : 
          </MDTypography>
          <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',color: colorver}}>
            {defaultdata.verification_date_time}
          </MDTypography>
        </MDBox>
      )
    }
    else{
      return(
        <>
        </>
      )
    }
  }
  const test_data =  (name,val,fntSize,colorver) => {
    return(
      <>
        <Grid item xs={4} md={4} >
          <Item>
          <MDTypography variant="" fontWeight="bold" style={{fontSize:fntSize}}  textTransform="capitalize"  size="large" required textAlign={"center"} justifyContent='center' alignItems='center'>
              {name}
          </MDTypography>
          </Item>
        </Grid>
        <Grid item xs={4} md={4} textAlign={"center"} justifyContent='center' alignItems='center'>
          {/* <Item> */}
          <MDTypography variant="" fontWeight="bold" style={{fontSize:fntSize}}  textTransform="capitalize"  size="large" required textAlign={"center"} justifyContent='center' alignItems='center'>
              :
          </MDTypography>
          {/* </Item> */}
        </Grid>
        <Grid item xs={4} md={4}>
          <Item>
          <MDTypography variant="" fontWeight="bold" style={{fontSize:fntSize,color:colorver}}  textTransform="capitalize"  size="large" required textAlign={"center"} justifyContent='center' alignItems='center'>
              {val}
          </MDTypography>  
          </Item>
        </Grid></>

    )
  }

  const current_tem = () => {
    if(currentstate){
      return(
        <Grid item xs={12} md={12} lg={12} style={{color:"black"}} container>
        <Card sx={{ height: "100%",width : "100%" }} >
          <MDBox m={1.5} borderBottom={2} textAlign={"center"} justifyContent='center' alignItems='center'>
            <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'2.4vw'}}>
              TOTAL BOOKINGS TODAY
          </MDTypography>
          
          </MDBox>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                    {test_data("ADULT",totaladults,'2vw',colorver)}
                    {test_data("CHILDREN",totalchild,'2vw',colorver)}
                    {test_data("FOR0IEGEN",totalfor,'2vw',colorver)}
                    {test_data("CAMERA",totalacamera,'2vw',colorver)}
                    {test_data("H-END CAMERA",totalhighendcamera,'2vw',colorver)}
                    {test_data("TOTAL TICKETS",totaltickets,'2vw',colorver,"50px")}
            </Grid>
          </Box>
        </Card>
      </Grid>
      )
    }
    else{
    if(ticketnotavailable){
      return(
        <Grid style={{width:'100%'}}>
          {alert_data()}
        </Grid>
      )
    }
    else{
      return(
        <Grid>
          {alert_data()}
          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} container spacing={2}>
            <Grid item xs={12} md={12} lg={4} style={{color:"black"}} >
            <Card sx={{ height: "100%",width : "100%" }} >
              <MDBox m={1.5} borderBottom={2} textAlign={"center"} justifyContent='center' alignItems='center'>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'38px'}}>
                  VISITORS DETAILS
              </MDTypography>
              </MDBox>
              <MDBox m={0.5}  textAlign={"left"} justifyContent='left' alignItems='left'>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px'}}>
                  NAME : 
                </MDTypography>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',color: colorver}}>
                  {defaultdata.Name}
                </MDTypography>
              </MDBox>
              <MDBox m={0.5}  textAlign={"left"} justifyContent='left' alignItems='left'>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px'}}>
                  TICKET NUMBER : 
                </MDTypography>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',color: colorver}}>
                {defaultdata.Ticket_Number}
                </MDTypography>
              </MDBox>
              <MDBox m={0.5}  textAlign={"left"} justifyContent='left' alignItems='left'>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px'}}>
                  BOOKING DATE : 
                </MDTypography>
                <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'25px',color: colorver}}>
                {defaultdata.Booking_Date}
                </MDTypography>
              </MDBox>
              {verified_at_data()}
            </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={5} style={{color:"black"}}>
              <Card sx={{ height: "100%",width : "100%" }} >
                <MDBox m={1.5} borderBottom={2} textAlign={"center"} justifyContent='center' alignItems='center'>
                  <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'38px'}}>
                    TICKET DETAILS
                </MDTypography>
                </MDBox>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                        {test_data("ADULT",defaultdata.Adult,'18px',colorver)}
                        {test_data("CHILDREN",defaultdata.Children,'18px',colorver)}
                        {test_data("FOR0IEGEN",defaultdata.Foreigner,'18px',colorver)}
                        {test_data("CAMERA",defaultdata.Camera,'18px',colorver)}
                        {test_data("H-END CAMERA",defaultdata.High_End_Camera,'18px',colorver)}
                        {test_data("TOTAL TICKETS",defaultdata.Total_Tickets,'25px',colorver,"50px")}
                </Grid>
              </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={3} style={{color:"black"}}>
              <Card sx={{ height: "100%",width : "100%" }} >
                <MDBox m={1.5} borderBottom={2} textAlign={"center"} justifyContent='center' alignItems='center'>
                  <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize:'30px'}}>
                    TOTAL BOOKING TODAY
                </MDTypography>
                </MDBox>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                        {test_data("ADULT",defaultdata.adults_ticket,'15px',colorver)}
                        {test_data("CHILDREN",defaultdata.childs_ticket,'15px',colorver)}
                        {test_data("FOR0IEGEN",defaultdata.foreigners_ticket,'15px',colorver)}
                        {test_data("CAMERA",defaultdata.camera_100,'15px',colorver)}
                        {test_data("H-END CAMERA",defaultdata.high_end_500,'15px',colorver)}
                        {test_data("TOTAL TICKETS",defaultdata.total_tickets,'20px',colorver,"50px",colorver)}
                </Grid>
              </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        
      )
    }
  }
  }

  return (
    <BasicLayout style={{overflowX: "hidden"}} image={''}>
      <Grid container m={1} spacing={2} style={{overflowX: "hidden"}}>
        <DashboardNavbar />
        <ToastContainer />
        <Card sx={{ height: "100%",width : "100%" }} style={{overflowX: "hidden"}}>
        <form
        className="rcw-sender"
        onSubmit={handleSubmit}
      >
          <Grid container spacing={0} color="dark" bgcolor={'green'} style={{overflowY: "hidden"}}>
            <Grid item xs={12} md={12} lg={12} style={{color:"black",overflowY: "hidden"}} container >
              <Grid item xs={12} md={12} lg={10} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={'100%'}
                      height={'100%'}
                      srcSet={summary1}
                      src={summary1}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={2} justifyContent='center' alignItems='center' textAlign='center' mt={9}>
                <MDInput type="text" value={inputticketnumber} onChange={(e) => setInputticketnumber(e.target.value)}  autoFocus="autofocus"  variant="outlined" size="large" name="ticket_number"  style={{color:"black",backgroundColor:'white'}} placehoder={"Enter"} />
              </Grid>
            </Grid>
          </Grid>
        </form>
        </Card>
      {current_tem()}
      </Grid>


      </BasicLayout>

  );
}

export default Scanner;
