/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Createusers page components
import Header from "layouts/createusers/components/Header";
  
// Data
import profilesListData from "layouts/profile/data/profilesListData";
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import 'reactjs-popup/dist/index.css';
// import { PieChart } from '@mui/x-charts/PieChart';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { axisClasses } from '@mui/x-charts';
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// Images
import dayjs from 'dayjs';

// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import DataTable from "examples/Tables/DataTable";
import { Bar ,Pie} from 'react-chartjs-2';
import 'chart.js/auto';

// Data
import authorsTableData from "layouts/createusers/data/authorsTableData";


function ChartViews() {

  const cookies = new Cookie();
  const navigate = useNavigate();
  
  console.log(cookies.get('token'))
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"

    }
  const [startdate, setStartdate] = useState(dayjs(new Date()));
  const [enddate, setEnddate] = useState(dayjs(new Date()));
  
  const [data, setData] = useState([]);
  const [allusersdata, setAllusersdata] = useState([]);
  const [allusers, setAllusers] = useState(false);
  const [barchartlabels, setBarchartlabels] = useState([false]);
  const [barchartdata ,setBarchartdata] = useState([]);
  const [piechartdata ,setPiechartdata] = useState([]);
  const [lastname ,setLastname] = useState("");
  const [email ,setEmail] = useState("");
  const [contactnumber,setContactnumber] = useState("");
  const [location ,setLocation1] = useState("");
  const [alltyperoles ,setAlltyperoles] = useState([]);
  const [newpassword1 ,setNewpassword1] = useState("");
  const [newpassword2 ,setNewpassword2] = useState("");
  const [roletype ,setRoletype] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const notify = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }
  // const chartSetting = {
  //   yAxis: [
  //     {
  //       label: 'rainfall (mm)',
  //     },
  //   ],
  //   width: 500,
  //   height: 300,
  //   sx: {
  //     [`.${axisClasses.left} .${axisClasses.label}`]: {
  //       transform: 'translate(-20px, 0)',
  //     },
  //   },
  // };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function callbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'get',
      url:base_url+`/api/auth/v1/api/chart/report/${Math.floor(new Date(startdate).getTime() / 1000)}/${Math.floor(new Date(enddate).getTime() / 1000)}/`,
      
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          console.log(response)
          setBarchartlabels(response.data.bar_chart_data.labels);
          setBarchartdata(response.data.bar_chart_data.data);
          setPiechartdata(response.data.pei_chart_data);
        }
        else{
          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
      console.log(error)
    });
  }



  useEffect(()=>{
    const response = axios({
      method: 'get',
      url:base_url+`/api/auth/v1/api/chart/report/${Math.floor(new Date(startdate).getTime() / 1000)}/${Math.floor(new Date(enddate).getTime() / 1000)}/`,
        // url:base_url+'/api/auth/v1/api/chart/report/<from>/<to>/',
      headers : {'Authorization': 'Token '+cookies.get('token')}
    })
    .then(function (response) {
        if(response.data){
          setBarchartlabels(response.data.bar_chart_data.labels);
          setBarchartdata(response.data.bar_chart_data.data);
          setPiechartdata(response.data.pei_chart_data);

        }
  })
  .catch(function (error) {
    console.log('error')
  }); 

  const response1 = axios({
    method: 'get',
    url:base_url+'/api/auth/v1/api/users/roles/',
    headers : {'Authorization': 'Token '+cookies.get('token')}
  })
  .then(function (response) {
      if(response){
        console.log(response)
        setAlltyperoles(response.data)
      }
    })
    .catch(function (error) {
      console.log('error')
    }); 
        
  }, [])

  const row_datas = alltyperoles.map((data,index) =>{
            return(
                  <option value={data.val} key={index}>{data.val}</option>
            )
        })

  const { columns, rows } = authorsTableData({'data' : allusersdata});

  const bar_chart_data = {
    labels:barchartlabels,
    datasets: [
      {
        label: 'Children',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: barchartdata.children_data,
      },
      {
        label: 'Adults',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: barchartdata.adult_data,
      },
      {
        label: 'Foriegen',
        backgroundColor: 'yellow',
        borderColor: 'green',
        borderWidth: 1,
        hoverBackgroundColor: 'yellow',
        hoverBorderColor: 'green',
        data: barchartdata.foriegen_data,
      },
      {
        label: 'Camera',
        backgroundColor: 'green',
        borderColor: 'blue',
        borderWidth: 1,
        hoverBackgroundColor: 'green',
        hoverBorderColor: 'blue',
        data: barchartdata.camera_data,
      },
      {
        label: 'High-end Camera',
        backgroundColor: 'orange',
        borderColor: 'black',
        borderWidth: 1,
        hoverBackgroundColor: 'orange',
        hoverBorderColor: 'black',
        data: barchartdata.h_end_camera_data,
      },
    ],
  };
   // Configuration options for the chart
   const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true, // Enable stacking for grouped bar chart
      },
      y: {
        stacked: true, // Enable stacking for grouped bar chart
        beginAtZero: true,
      },
    },
  };

  const pie_chart_data = {
    labels: ['Children', 'Adult', 'Foriegin', 'Camera','H-End Camera'],
    datasets: [
      {
        data: piechartdata,
        backgroundColor: ['blue', 'pink', 'yellow', 'orange','green'],
        hoverBackgroundColor: ['blue', 'pink', 'yellow', 'orange','green'],
      },
    ],
  };

const  pie_chart_options = {
    responsive: true,
    maintainAspectRatio: false,
  };
 


  const show_data = () => {
    if (allusers === false) {
      return(
        <div>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                DAYS CHARTS
              </MDTypography>
            </MDBox>
            <MDBox m={1.5} borderBottom={2}>
              <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                DATE AND TIME FILTER
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} color="dark">
              <Grid item xs={12} md={12} lg={6} style={{color:"black"}}>
                <MDBox m={1.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem label={'From Date'}>
                      <DateTimePicker views={['year', 'month','day']}  value={startdate}  onChange={(newValue) => setStartdate(newValue)} />
                    </DemoItem>
                  </LocalizationProvider>
                </MDBox>
                <MDBox m={1.5} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem label={'To Date'}>
                      <DateTimePicker views={['year', 'month','day']}  value={enddate} onChange={(newValue1) => setEnddate(newValue1)}/>
                    </DemoItem>
                  </LocalizationProvider>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' my={10} >
                    <MDButton variant="gradient" color="info" size="large" onClick={(e) => callbackRequest(e)}>Submit</MDButton>
              </Grid>
            </Grid>
            <MDBox pt={3}>
              <MDTypography variant="h2" color="black" justifyContent='center' alignItems='center' textAlign='center'>
                  Pie CHARTS
              </MDTypography>
              <div style={{ width: '100%', margin: 'auto' ,height : "20em"}}>
                <Pie data={pie_chart_data} options={pie_chart_options} />
              </div>
              <MDTypography variant="h2" color="black" justifyContent='center' alignItems='center' textAlign='center'>
                  BAR CHARTS
              </MDTypography>
              <div style={{ width: '100%', margin: 'auto',height : "20em" }}>
                <Bar data={bar_chart_data} options={options} />
              </div>
            </MDBox>
          </Card>   
        </div>
      )
    }
    else{
      return(
        <div >
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                HOURLY CHARTS
              </MDTypography>
            </MDBox>
            <MDBox m={1.5} borderBottom={2}>
              <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                DATE AND TIME FILTER
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} color="dark">
              <Grid item xs={12} md={12} lg={6} style={{color:"black"}}>
                <MDBox m={1.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem label={'From Date'}>
                      <DateTimePicker views={['year', 'month','day']}  value={startdate}  onChange={(newValue) => setStartdate(newValue)} />
                    </DemoItem>
                  </LocalizationProvider>
                </MDBox>
                <MDBox m={1.5} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem label={'To Date'}>
                      <DateTimePicker views={['year', 'month','day']}  value={enddate} onChange={(newValue1) => setEnddate(newValue1)}/>
                    </DemoItem>
                  </LocalizationProvider>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' my={10} >
                    <MDButton variant="gradient" color="info" size="large" onClick={(e) => callbackRequest(e)}>Submit</MDButton>
              </Grid>
            </Grid>
            <MDBox pt={3}>
              <MDTypography variant="h2" color="black" justifyContent='center' alignItems='center' textAlign='center'>
                  Pie CHARTS
              </MDTypography>
              <div style={{ width: '100%', margin: 'auto' ,height : "20em"}}>
                <Pie data={pie_chart_data} options={pie_chart_options} />
              </div>
              <MDTypography variant="h2" color="black" justifyContent='center' alignItems='center' textAlign='center'>
                  BAR CHARTS
              </MDTypography>
              <div style={{ width: '100%', margin: 'auto',height : "20em" }}>
                <Bar data={bar_chart_data} options={options} />
              </div>
            </MDBox>
          </Card>  
      </div>
      )
    }

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <MDBox mb={2} /> */}
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} >
            <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(false)}>DAYS CHART</MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
              <MDButton variant="gradient" color="info" size="large" onClick={() => setAllusers(true)}>HOURLY CHATS</MDButton>
            </Grid>
            <Grid container spacing={12} justifyContent='center' alignItems='center' textAlign='center'>
              <Grid item xs={12}>
                {show_data()}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default ChartViews;
