/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import MDAlert from "components/MDAlert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
// Data
import profilesListData from "layouts/profile/data/profilesListData";
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { width } from "@mui/system";
import profile_image from 'assets/images/custom_images/profile.png'
import MDAvatar from "components/MDAvatar";
import routes from "routes";

// Images
// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

function Overview() {
  const cookies = new Cookie();
  const navigate = useNavigate();
  
  console.log(cookies.get('token'))
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"

    }
  const [data, setData] = useState([]);
  const [editdata, setEditdata] = useState(false);
  const [changepassdata, setChangepassdata] = useState(false);
  const [description ,setDescription] = useState("");
  const [firstname ,setFirstname] = useState("");
  const [lastname ,setLastname] = useState("");
  const [contactnumber,setContactnumber] = useState("");
  const [location ,setLocation1] = useState("");
  const [oldpassword ,setOldpassword] = useState("");
  const [newpassword1 ,setNewpassword1] = useState("");
  const [newpassword2 ,setNewpassword2] = useState("");

  const notify = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    })
  }

  function changepasswordcallbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'post',
      url:base_url+'/api/auth/v1/api/profiles/',
      data: {
        'new_password' : newpassword1,
      },
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response.data){
          {notify("Password Change successfully")}
          
          const cookies = new Cookie();
          cookies.remove('token',{ path: '/'  ,maxAge: 14 * 60 * 60,
          sameSite: true}); 
          cookies.remove('role_type',{ path: '/'  ,maxAge: 14 * 60 * 60,
          sameSite: true}); 
      
          console.log(cookies.get('token'))    
                  window.location.href = "/authentication/sign-in"

        }
        else{
          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
      console.log(error)
    });
  }


  function callbackRequest(e){
    e.preventDefault();

    const response = axios({
      method: 'patch',
      url:base_url+'/api/auth/v1/api/profiles/',
      data: {
        'first_name' : firstname,
        'last_name' : lastname,
        'contact_number' : contactnumber,
        'description' : description,
        'location' : location
      },
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response.data){
          setEditdata(false);
          {notify("Profile Update Successfully !!")}
          setData(response.data);
          setDescription(response.data.description);
          setLocation1(response.data.location);
          setContactnumber(response.data.contact_number);
          setFirstname(response.data.first_name);
          setLastname(response.data.last_name);
        }
        else{
          console.log('errordmskjvhcgdstycbduij')
        }
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  // useEffect(()=>{
  //   const response = axios({
  //     method: 'get',
  //     url:base_url+'/api/auth/v1/api/profiles/',
  //     headers : {'Authorization': 'Token '+cookies.get('token')}
  //   })
  //   .then(function (response) {
  //       if(response.data){
  //         setData(response.data);
  //         setDescription(response.data.description);
  //         setLocation1(response.data.location);
  //         setContactnumber(response.data.contact_number);
  //         setFirstname(response.data.first_name);
  //         setLastname(response.data.last_name);
  //       }
  // })
  // .catch(function (error) {
  //   console.log(error)
  //   // setMessage1("Please enter correct username or password");
  // }); 
    
  // }, [])

  // useEffect(()=>{
    
  //   if (cookies.get('token') === undefined){
  //             window.location.href = "/authentication/sign-in"
  //   }

  //   if (cookies.get('role_type') === undefined && cookies.get('token') != undefined){
  //     const response = axios({
  //       method: 'get',
  //       url:base_url+'/api/auth/v1/api/users/',
  //       headers : {'Authorization': 'Token '+cookies.get('token')}
  //     })
  //     .then(function (response) {
  //         if(response.data.roles){
  //           // const [cookies, setCookie] = cookie(['access_token', 'refresh_token'])
  //           // let expires = new Date()
  //           // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
  //           // console.log(cookie.set('access_token', "response.data.access_token"))
  //           cookies.set('role_type',response.data.roles , { path: '/'  ,maxAge: 14 * 60 * 60,
  //           sameSite: true});
  //         }
  //   })
  // }

  //   const response = axios({
  //       method: 'get',
  //       url:base_url+`/api/auth/v1/api/summary/dashboard/${Math.floor(new Date(startdate).getTime() / 1000)}/${Math.floor(new Date(enddate).getTime() / 1000)}/`,
  //       headers : {'Authorization': 'Token '+cookies.get('token')}
  //     })
  //     .then(function (response) {
  //         if(response.data){
  //           setTotalamt(response.data.totalamt)
  //           setTotaltickets(response.data.totaltickets)
  //           setTotaladults(response.data.adults)
  //           setTotalchild(response.data.childs)
  //           setTotalfor(response.data.foreigner)
  //           setTotalacamera(response.data.camera)
  //           setTotalhighendcamera(response.data.hcamera)
  //           // setPdf(`http://127.0.0.1:3000/api/auth/v1/api/summary/report/?from=${Math.floor(new Date(startdate).getTime() / 1000)}&to=${Math.floor(new Date(enddate).getTime() / 1000)}&type=pdf`)
  //           // setCsv(`http://127.0.0.1:3000/api/auth/v1/api/summary/report/?from=${Math.floor(new Date(startdate).getTime() / 1000)}&to=${Math.floor(new Date(enddate).getTime() / 1000)}&type=excel`)
  //           setPdf(`https://nandankanan.fourthdimension.online/summary_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=pdf`)
  //           setCsv(`https://nandankanan.fourthdimension.online/summary_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=excel`)
  
  //         }
  //   })
  //   .catch(function (error) {
  //     // {danger_notify("User Does Not Exists")}
  //             window.location.href = "/authentication/sign-in"

  //   });
  //   // window.location.reload(false)

  //   if (cookies.get('token') !== undefined){
  //     if(routes.length === 2 ){
  //       toast.success("YOU ARE SUCCESSFULLY LOGIN WAIT 5 SECONDS FOR ALL DATA LOAD", {
  //         position: toast.POSITION.TOP_CENTER,
  //         draggablePercent: 100
  //     })
  //       setTimeout(() => {
  //         window.location.href = "/profile"
  //     }, 5000);
  //   }
      
  //   }

  // }, [])

  useEffect(()=>{
    
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"
    }

    if (cookies.get('role_type') === undefined && cookies.get('token') != undefined){
      const response = axios({
        method: 'get',
        url:base_url+'/api/auth/v1/api/users/',
        headers : {'Authorization': 'Token '+cookies.get('token')}
      })
      .then(function (response) {
          if(response.data.roles){
            // const [cookies, setCookie] = cookie(['access_token', 'refresh_token'])
            // let expires = new Date()
            // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
            // console.log(cookie.set('access_token', "response.data.access_token"))
            cookies.set('role_type',response.data.roles , { path: '/'  ,maxAge: 14 * 60 * 60,
            sameSite: true});
          }
    })
  }

    const response = axios({
      method: 'get',
      url:base_url+'/api/auth/v1/api/profiles/',
      headers : {'Authorization': 'Token '+cookies.get('token')}
    })
    .then(function (response) {
        if(response.data){
          setData(response.data);
          setDescription(response.data.description);
          setLocation1(response.data.location);
          setContactnumber(response.data.contact_number);
          setFirstname(response.data.first_name);
          setLastname(response.data.last_name);
        }
  })
  .catch(function (error) {
    console.log(error)
    // setMessage1("Please enter correct username or password");
  }); 
    // window.location.reload(false)
    if (cookies.get('token') !== undefined){
      if(routes.length === 3 ){
        toast.success("YOU ARE SUCCESSFULLY LOGIN WAIT 5 SECONDS FOR ALL DATA LOAD", {
          position: toast.POSITION.TOP_CENTER,
          draggablePercent: 100
      })
        setTimeout(() => {
          window.location.href = "/profile"
      }, 5000);
    }
      
    }

    if (cookies.get('token') !== undefined){
      if(cookies.get('role_type') === 5 ){
          
      }
    }

  }, [])

  const show_data = () => {
    if (editdata === false) {
      return(
        <div>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            profile information
            </MDTypography>
            <MDTypography  variant="body2" color="secondary" px={5} onClick={() => setEditdata(true)}>
              <Tooltip title="Edit Profile" placement="top">
                <Icon >edit</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>

            <MDBox key="description" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" color="text" fontWeight="light">
                  {data.description}
              </MDTypography>
            </MDBox>
            <MDBox key="first_name" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              First Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.first_name}
              </MDTypography>
            </MDBox>   
            <MDBox key="last_name" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Last Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.last_name}
              </MDTypography>
            </MDBox>
            <MDBox key="username" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              User Name : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.username}
              </MDTypography>
            </MDBox>

            <MDBox key="email" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Email Id : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.email}
              </MDTypography>
            </MDBox> 
            <MDBox key="contact_number" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Contact Number : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.contact_number}
              </MDTypography>
            </MDBox>   
            <MDBox key="location" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Location : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.location}
              </MDTypography>
            </MDBox>  
            <MDBox key="location1" display="flex" py={1} pr={2} px={3}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Last Login : &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; {data.last_login}
              </MDTypography>
            </MDBox>   
        </div>
      )
    }
    else{
      return(
        <div>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  profile information
                  </MDTypography>
          </MDBox>
          <MDBox key="description" display="flex" py={1} pr={2} px={3}>
            <MDTypography variant="button" color="text" fontWeight="light">
                <MDInput type="Textarea" variant="outlined" size="extra-large"  multiline rows={2} value={description} onChange={(e) => setDescription(e.target.value)}/>
            </MDTypography>
          </MDBox>
        {/* <MDBox key="username" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          User Name : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text" >
            &nbsp; {data.username}
          </MDTypography>
        </MDBox>

        <MDBox key="email" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          Email Id : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text" >
            &nbsp; {data.email}
          </MDTypography>
        </MDBox>  */}
        
        
        <MDBox key="first_name" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          First Name : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            <MDInput type="text" value={firstname} size="small"  onChange={(e) => setFirstname(e.target.value)}/>
          </MDTypography>
        </MDBox>   
        <MDBox key="last_name" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          Last Name : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            <MDInput type="text" value={lastname} size="small"  onChange={(e) => setLastname(e.target.value)}/>
          </MDTypography>
        </MDBox>
        <MDBox key="contact_number" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          Contact Number : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text"px={0.8}>
            <MDInput type="text" value={contactnumber} size="small"  onChange={(e) => setContactnumber(e.target.value)}/>
          </MDTypography>
        </MDBox>   
        <MDBox key="location" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          Location : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            <MDInput type="text" value={location} size="small"  onChange={(e) => setLocation1(e.target.value)}/>
          </MDTypography>
        </MDBox> 
        <MDBox key="button" display="flex" py={1} pr={2}  justifyContent='center' alignItems='center'>
          <MDButton variant="gradient" px={1} color="info" onClick={(e) => callbackRequest(e)}>Update</MDButton>  
        </MDBox>
      </div>
      )
    }

  }

  const change_pass_data = () => {
    if (changepassdata === false) {
      return(
        <div>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Change Password
            </MDTypography>
            <MDTypography  variant="body2" color="secondary" px={5} onClick={() => setChangepassdata(true)}>
              <Tooltip title="Edit Profile" placement="top">
                <Icon >edit</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        </div>
      )
    }
    else{
      return(
        <div>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Change Password
            </MDTypography>
            <MDTypography  variant="body2" color="secondary" px={5} onClick={() => setChangepassdata(false)}>
              <Tooltip title="Edit Profile" placement="top">
                <Icon >cut</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>

        <MDBox key="old_pass" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          Old Password : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            <MDInput type="password"  size="small"  onChange={(e) => setOldpassword(e.target.value)}/>
          </MDTypography>
        </MDBox>   
        <MDBox key="last_name" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          New Password : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            <MDInput type="password"  size="small"  onChange={(e) => setNewpassword1(e.target.value)}/>
          </MDTypography>
        </MDBox>
        {/* <MDBox key="contact_number" display="flex" py={1} pr={2} px={3}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" py={1}>
          re-enter password : &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text"px={0.8}>
            <MDInput type="text"  size="small"  onChange={(e) => setNewpassword2(e.target.value)}/>
          </MDTypography>
        </MDBox>    */}
        
        <MDBox key="button" display="flex" py={1} pr={2}  justifyContent='center' alignItems='center'>
          <MDButton variant="gradient" px={1} color="info" onClick={(e) => changepasswordcallbackRequest(e)}>Update</MDButton>  
        </MDBox>
      </div>
      )
    }

  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />

      {/* <MDBox mb={2} /> */}
      <Header name={data.full_name} destination={data.roles}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} >
            <Grid item xs={12} xl={6} lg={3} variant="gradient"  md={6}  mb={50} justifyContent='center' alignItems='center' textAlign='center' sx={{ display: "flex" }}>
              {/* <Card sx={{ height: "100%"}}> */}
                {change_pass_data()}
              {/* </Card> */}
            </Grid>
            
            <Grid spacing={6} item xs={12} xl={6} lg={3} md={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              
              {/* <Card sx={{ height: "100%"}}> */}
                
                  {show_data()}
              {/* </Card> */}
              

                {/* <ProfileInfoCard
                  title="profile information"
                  description={data.description}
                  info={{
                    Username : data.username,
                    FirstName: data.first_name,
                    LastName : data.last_name,
                    mobile: data.contact_number,
                    email: data.email,
                    location: data.location,
                  }}
                  // social={[]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                /> */}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            {/* <Grid item xs={12} xl={4}>
              <PlatformSettings />

              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
