/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
export default function data(props) {
  const navigate = useNavigate();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const Author = ({ name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
    function callbackRequest(id){
      navigate(`/updatetickets/${id}`)
    }  
  const Statusdata = ({val}) => {
    if(val == 'Done') {
              return(
                        <MDBox ml={-1}>
                          <MDBadge badgeContent="Completed" color="success" variant="gradient" size="sm" />
                        </MDBox>
                        )
    }
    else if(val == true) {
      return(
                <MDBox ml={-1}>
                  <MDBadge badgeContent="Completed" color="success" variant="gradient" size="sm" />
                </MDBox>
                )
}
    else{
      return(<MDBox ml={-1}>
              <MDBadge badgeContent="Not Completed" color="dark" variant="gradient" size="sm" />
          </MDBox>)
    }
  }
  var dict = []; // Create an empty array

  if (props.data) {
    const row_datas = props.data.map((data,index) =>
    dict.push(
                        {
                          srn: <Author name={index+1}  />,
                          contact_number : <Author name={data['Contact Number']}  />,
                          whatsapp_name : <Author name={data['Whatsapp Name']}  />,
                          verification: <Statusdata val={data['Verification']}  />,
                          verification_code : <Author name={data['Verification Code']}  />,
                          time_stmape : <Author name={data['Time Stamp']}  />,
                          booking_date : <Author name={data['Booking Date']}  />,
                          Adult_tickets : <Author name={data['Adult Tickets']}  />,
                          Child_tickets : <Author name={data['Child Tickets']}  />,
                          Foreigner_tickets : <Author name={data['Foreigner Tickets']}  />,
                          Camera_tickets : <Author name={data['Camera Tickets']}  />,
                          H_END_Camera_tickets : <Author name={data['H-END Camera Tickets']}  />,
                          
                          total_tickets : <Author name={data['Total_Tickets']}  />,
                          total_amt_with_out_serverice_charge : <Author name={data['Total Amount Without Service Charge']}  />,
                          total_amt_with_serverice_charge : <Author name={data['Total Amounts']}  />,
                          transaction_id : <Author name={data['Transaction Id']}  />,
                          ticket_id : <Author name={data['Ticket Id']}  />,
                          username: <Author name={data['Token_Verified_By_Username']}  />,
                          status: <Statusdata val={data['Payment Status']}  />,
                        }
                      )
                    )
                      }

  console.log(dict)                
  return {
    columns: [
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Contact Number", accessor: "contact_number", align: "center" },
      { Header: "Whatsapp Name", accessor: "whatsapp_name", align: "center" },
      { Header: "Verification", accessor: "verification", align: "center" },
      { Header: "Verification Code", accessor: "verification_code", align: "center" },
      { Header: "Time Stamp", accessor: "time_stmape", width: "0%", align: "center" },
      { Header: "Booking Date", accessor: "booking_date", width: "0%", align: "center" },
      { Header: "Adult Tickets", accessor: "Adult_tickets", align: "center" },
      { Header: "Child Tickets", accessor: "Child_tickets", align: "center" },
      { Header: "Foreigner Tickets", accessor: "Foreigner_tickets", align: "center" },
      { Header: "Camera Tickets", accessor: "Camera_tickets", align: "center" },
      { Header: "H-END Camera Tickets", accessor: "H_END_Camera_tickets", align: "center" },


      { Header: "Total Tickets", accessor: "total_tickets", align: "center" },
      { Header: "Total Amount Without Service Charge", accessor: "total_amt_with_out_serverice_charge", align: "center" },
      { Header: "Total Amounts", accessor: "total_amt_with_serverice_charge", align: "center" },
      { Header: "Transaction Id", accessor: "transaction_id", align: "center" },
      { Header: "Ticket Id", accessor: "ticket_id", align: "center" },
      { Header: "Token Verified By Username ", accessor: "username", align: "center" },
      { Header: "Payment Status", accessor: "status", align: "center" },
    ],
    rows: dict
  };
}
