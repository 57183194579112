/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { StaticDateTimePicker } from '@mui/x-date-pickers';

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import MDTypography from "components/MDTypography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import dayjs from 'dayjs';
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/detailedreport/data/authorsTableData";

import Card from "@mui/material/Card";
import summary1 from "assets/images/custom_images/summary1.png";
import summary2 from "assets/images/custom_images/summary2.png";
import summary3 from "assets/images/custom_images/summary3.png";
import summary5 from "assets/images/custom_images/summary5.png";
import summary6 from "assets/images/custom_images/summary6.png";
import summary7 from "assets/images/custom_images/summary7.jpeg";
import summary8 from "assets/images/custom_images/summary8.jpg";




function Detailedreport() {
  const navigate = useNavigate();
  const [startdate, setStartdate] = useState(dayjs(new Date()));
  const [enddate, setEnddate] = useState(dayjs(new Date()));
  const [totaltable, setTotaltable] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdf, setPdf] = useState("");
  const [csv, setCsv] = useState("");
  function getFormattedDate(dateString,hours) {
    var date = new Date(dateString);
    date.setHours(hours, 31, 0);   // Set hours, minutes and seconds
    return date;
 }



  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const cookies = new Cookie();
  if ([1,2,3,5,6].includes(cookies.get('role_type')) === true) {
  }
  else{
    navigate('/dashboard')
  }

  
  useEffect(()=>{

    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"

    }
    const response = axios({
        method: 'get',
        url:base_url+`/api/auth/v1/api/detailed/dashboard/${Math.floor(new Date(startdate).getTime() / 1000)}/${Math.floor(new Date(enddate).getTime() / 1000)}/`,
        headers : {'Authorization': 'Token '+cookies.get('token')}
      })
      .then(function (response) {
          if(response.data){
            setTotaltable(response.data)
            setPdf(`https://nandankanan.fourthdimension.online/detailed_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=pdf`)
            setCsv(`https://nandankanan.fourthdimension.online/detailed_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=excel`)

    
          }
    })
    
  }, [])


  function callbackRequest(e){
    e.preventDefault();
    const response = axios({
      method: 'get',
      url:base_url+`/api/auth/v1/api/detailed/dashboard/${Math.floor(new Date(startdate).getTime() / 1000)}/${Math.floor(new Date(enddate).getTime() / 1000)}/`,
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          setTotaltable(response.data)
          setPdf(`https://nandankanan.fourthdimension.online/detailed_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=pdf`)
          setCsv(`https://nandankanan.fourthdimension.online/detailed_report?from=${Math.floor(getFormattedDate(startdate,5).getTime() / 1000)}&to=${Math.floor(getFormattedDate(enddate,23).getTime() / 1000)}&type=excel`)

        }
    })
    .catch(function (error) {
          // {danger_notify("User Does Not Exists")}
          console.log(error)
    });
  }
  console.log(totaltable,'totaltable')
  const { columns, rows } = authorsTableData({'data' : totaltable});



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ height: "100%" }}>
          <MDBox m={1.5} borderBottom={2}>
            <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
              DATE AND TIME FILTER
          </MDTypography>
          </MDBox>
          <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={6} style={{color:"black"}}>
              <MDBox m={1.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label={'From Date'}>
                    <DateTimePicker views={['year', 'month','day']}  value={startdate}  onChange={(newValue) => setStartdate(newValue)} />
                  </DemoItem>
                </LocalizationProvider>
              </MDBox>
              <MDBox m={1.5} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label={'To Date'}>
                    <DateTimePicker views={['year', 'month','day']}  value={enddate} onChange={(newValue) => setEnddate(newValue)}/>
                  </DemoItem>
                </LocalizationProvider>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' my={10} >
              <MDButton variant="gradient" color="info" size="large" onClick={(e) => callbackRequest(e)}>Submit</MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={3} justifyContent='center' alignItems='center' textAlign='center' my={8.5} >
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MDButton variant="gradient" color="info" size="large">Download</MDButton>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <a href="mailto:fdsgms@gmail.com" target="_blank" rel="noopener noreferrer">
                  <MenuItem onClick={handleClose}>
                    <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
                    Send Email For Report
                    </MDTypography>
                  </MenuItem>
                  </a>
                  
                  {/* <a href={pdf}  target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}> 
                  <MenuItem onClick={handleClose}>
                    <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
                      PDF
                    </MDTypography>
                  </MenuItem></a>
                  <a href={csv} download target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}> <MenuItem onClick={handleClose}> 
                    <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
                      CSV
                    </MDTypography>
                  </MenuItem></a> */}
                </Menu>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox py={3}>
      <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" justifyContent='center' alignItems='center' textAlign='center'>
              DETAILED REPORT
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                entriesPerPage={true}
                showTotalEntries={true}
                noEndBorder
              />
            </MDBox>
          </Card>  
        {/* <Card sx={{ height: "100%" }} >
          <MDBox m={1.5} borderBottom={2} >
            <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
              SUMMARY
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={6} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary1}
                      src={summary1}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Total Money
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  ₹  100
                </MDTypography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={6} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary2}
                      src={summary2}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Total Tickets
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Card> */}
      </MDBox>
      {/* <MDBox py={3}>
        <Card sx={{ height: "100%" }} >
          
          <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={6} style={{color:"black"}} container>
              <MDBox m={1.5}  justifyContent='center' alignItems='center' textAlign='center'>
                <MDTypography justifyContent='center' alignItems='center' textAlign='center' variant="h3" fontWeight="bold" textTransform="capitalize" py={1.5} size="large" >
                  REPORT DOWNLOAD
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} lg={6} style={{color:"black"}} container>
              <MDBox m={1.5} alignItems='right' textAlign='right'>
              <Button
                  id="basic-button"
                  bgColor={"error"}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  fontWeight="bold"
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{ color: "white" }}>
                    DOWNLOAD
                  </MDTypography>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <a href={pdf} download target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}> <MenuItem onClick={handleClose}>
                     <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
                       PDF
                    </MDTypography>
                  </MenuItem></a>
                  <a href={csv} download target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}> <MenuItem onClick={handleClose}> 
                    <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
                       CSV
                    </MDTypography>
                  </MenuItem></a>
                </Menu>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox> */}
      {/* <MDBox py={3}>
        <Card sx={{ height: "100%" }}>
          <MDBox m={1.5} borderBottom={2} >
            <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
              TICKETS
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={3} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary3}
                      src={summary3}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Adults
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={3} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary5}
                      src={summary5}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Children
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={3} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary6}
                      src={summary6}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Foreigners
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={3} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary7}
                      src={summary7}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  Camera
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={3} style={{color:"black"}} container>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                  <img 
                      width={100}
                      srcSet={summary8}
                      src={summary8}
                      alt="A"
                      loading="lazy"
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' >
                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                  High End Camera
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                  100
                </MDTypography>
              </Grid>
            </Grid>

          </Grid>
        </Card>
      </MDBox> */}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Detailedreport;
