/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import * as React from 'react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { StaticDateTimePicker } from '@mui/x-date-pickers';

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import dayjs from 'dayjs';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";


import Card from "@mui/material/Card";
import summary1 from "assets/images/custom_images/summary1.png";
import summary2 from "assets/images/custom_images/summary2.png";
import summary3 from "assets/images/custom_images/summary3.png";
import summary5 from "assets/images/custom_images/summary5.png";
import summary6 from "assets/images/custom_images/summary6.png";
import summary7 from "assets/images/custom_images/summary7.jpeg";
import summary8 from "assets/images/custom_images/summary8.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import routes from "routes";

function SearchFunc() {
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isVisible, setVisible] = useState(false)
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [data, setData] = React.useState(null);
  const [age, setAge] = React.useState('Transaction Id');
  const [selectidval, setSelectidval] = React.useState('');
  const [shownormaldata,setShownormaldata] = React.useState(false);
  const [bydefault,setBydefault] = React.useState(true);

 const cookies = new Cookie();

  useEffect(()=>{
    
    if (cookies.get('token') === undefined){
              window.location.href = "/authentication/sign-in"
    }

  }, [])
  
  function callbackRequest(e){
    let id_type 
    if (age === 'Transaction Id') {
      id_type = '10'
    }
    if (age === 'Contact Number') {
      id_type = '101'
    }
    if (age === 'Ticket ID') {
      id_type = '1001'
    }
    e.preventDefault();
    const response = axios({
      method: 'get',
      url:base_url+`/api/auth/v1/api/user/ticket/details/${id_type}/${selectidval}/`,
      headers : {'Authorization': 'Token '+cookies.get('token')}

    })
    .then(function (response) {
        if(response){
          setData(response.data)
          if(response.data.count === 0){
            setBydefault(false)
          }
          else{
            setBydefault(true)
            setShownormaldata(true)
          }
         }
    })
    .catch(function (error) {
          // {danger_notify("User Does Not Exists")}
          console.log(error)
    });
  }
  console.log('data',data)
  const final_data = () => {
    if (data){
      if(data.data.length >= 0){

                                    return(
                                      <>
                                      {
                                        data.data.map((dict, i) => (
                                          <>
                                          <MDBox
                                            mx={2}
                                            mt={3}
                                            py={3}
                                            px={2}
                                            variant="gradient"
                                            bgColor="success"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                          >
                                            <MDTypography variant="h5" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                                              Verification Date  :  {dict.Verification_Date_and_Time}
                                            </MDTypography>
                                          </MDBox>
                                          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} container key={i} mt={2}>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Sr. No.
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {i+1}
                                                </MDTypography>
                                              </Grid>
                                              
                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Name
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Name}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Adult Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Adult_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Child Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Child_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Foreigner Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Foreigner_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Camera Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Camera_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  H-END CAMERA Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.H_END_Camera_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                  Total Ticket
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Total_Tickets}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Service Charge Amount
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Service_Charge_Amount}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Total Amounts without Service Charges
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Total_Amounts_without_Service_Charges}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Total Amounts
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Total_Amounts}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Transaction Id
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h6" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Transaction_Id}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Langauge
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.langauge}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Contact Number
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Contact_Number}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' px={6}>
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Payment Status
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Payment_Status}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left' >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Tocken Verified By
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Tocken_Verified_By}
                                                </MDTypography>
                                              </Grid>

                                              <Grid item xs={12} md={12} lg={3} justifyContent='left' alignItems='left' textAlign='left'px={6} >
                                                <MDTypography variant="h4" fontWeight="light" textTransform="capitalize" py={1} size="large" required>
                                                Verification Code
                                                </MDTypography>
                                              </Grid>
                                              <Grid item xs={12} md={12} lg={3} justifyContent='center' alignItems='center' textAlign='center' >
                                                <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                                                {dict.Verification_Code}
                                                </MDTypography>
                                              </Grid>
                                          </Grid>
                                          </>

                                        ))
                                      }
                                      </>
                                    )
                                }
    }
  else{
    return(
      <>
      <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="error"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h1" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                VISITOR DETAILS NOT FOUND
              </MDTypography>
            </MDBox>
      </>
    )
  }
  }

  const data_show = () => {
    if (shownormaldata && bydefault === true){
      return(
        <Card sx={{ height: "100%" }} >
          <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h1" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                  VISITOR DETAILS
                </MDTypography>
          </MDBox>
          <MDBox m={1.5} borderBottom={2} >
            <MDTypography variant="h1" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
            {final_data()}
            </MDTypography>
          </MDBox>
        </Card>
      )
    }
    else if(shownormaldata === false && bydefault === true){
      return(
        <>
        
        </>
      )
    }
    else{
        return(
        <Card sx={{ height: "100%" }} >
          <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h1" color="white" justifyContent='center' alignItems='center' textAlign='center'>
                  VISITOR DETAILS NOT FOUND PLEASE ENTER CORRECT {age}
                </MDTypography>
          </MDBox>
          {/* <MDBox m={1.5} borderBottom={2} >
            <MDTypography variant="h1" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
            {final_data()}
            </MDTypography>
          </MDBox> */}
        </Card>
          )
        }
  }
  // alert(final_data)
  // console.log(final_data)
  const handleChange = (event) => {
    setAge(event.target.value);
    setSelectidval('')
    setBydefault(true)
    setShownormaldata(false)
  };
  const SelecthandleChange = (event) => {
    setSelectidval(event.target.value);
  };
  return (
    <DashboardLayout>
      <ToastContainer />
      
      <DashboardNavbar />
      
      <MDBox py={3}>
        <Card sx={{ height: "100%" }}>
          <MDBox m={1.5} borderBottom={2}>
            <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize" py={1} size="large" required>
                VISITOR DATA FILTER
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={6} style={{color:"black"}}>
              <MDBox m={1.5}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Select
                </InputLabel>
                <NativeSelect
                  defaultValue={age}
                  inputProps={{
                    name: 'age',
                    // id: 'uncontrolled-native',
                  }}
                  onChange={handleChange}
                >
                  <option value={'Transaction Id'}>Transaction Id</option>
                  <option value={'Contact Number'}>Contact Number</option>
                  <option value={'Ticket Id'}>Ticket Id</option>
                </NativeSelect>
              </FormControl>
              </MDBox>
              <MDBox m={1.5} >
                <FormControl fullWidth >
                  <InputLabel htmlFor="outlined-adornment-amount">{age}</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={selectidval}
                      onChange={SelecthandleChange}
                      label={age}
                    />
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={6} justifyContent='center' alignItems='center' textAlign='center' my={6} >
                  <MDButton variant="gradient" color="info" size="large" onClick={(e) => callbackRequest(e)}>Submit</MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox py={3}>
        {data_show()}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default SearchFunc;
