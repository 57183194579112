/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
export default function data(props) {
  const navigate = useNavigate();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const Author = ({ name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
    function callbackRequest(e,name){
      navigate(`/users/${name}`,{ state: { id1: 7, color: 'green' } })
    }  
  const Statusdata = ({val}) => {
    if(val) {
              return(
                        <MDBox ml={-1}>
                          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
                        </MDBox>
                        )
    }
    else{
      return(<MDBox ml={-1}>
              <MDBadge badgeContent="Inactive" color="dark" variant="gradient" size="sm" />
          </MDBox>)
    }
  }
  var dict = []; // Create an empty array
  if (props.data.data) {
    const row_datas = props.data.data.map((data,index) =>
                
                  dict.push(
                        {
                          srn: <Author name={index+1}  />,
                          name: <Author name={data.full_name}  />,
                          username: <Author name={data.username}  />,
                          email: <Author name={data.email}  />,
                          role_type: <Author name={data.roles}  />,
                          
                          status: <Statusdata val={data.is_active}  />,
                          last_login: <Author name={data.last_login}  />,
                          action: (
                            <MDTypography component="a" variant="caption" color="text" fontWeight="medium" onClick={(e) => callbackRequest(e,data.id)}>
                              Edit
                            </MDTypography>
                          )
                        }

                      )
                    )
                      }
                      
  return {
    columns: [
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Name", accessor: "name", width: "0%", align: "center" },
      // { Header: "Username", accessor: "username", width: "0%", align: "center" },
      { Header: "Email Id", accessor: "email", width: "0%", align: "center" },
      { Header: "Role Type", accessor: "role_type", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Last Login", accessor: "last_login", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: dict
  };
}
