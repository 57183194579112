/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie'

export default function data(props) {
  const navigate = useNavigate();
  const cookies = new Cookie();
  const colm = []
  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const Author = ({ name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
    function callbackRequest(id){
      navigate(`/updatetickets/${id}`)
    }  
  const Statusdata = ({val}) => {
    if(val) {
              return(
                        <MDBox ml={-1}>
                          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
                        </MDBox>
                        )
    }
    else{
      return(<MDBox ml={-1}>
              <MDBadge badgeContent="Inactive" color="dark" variant="gradient" size="sm" />
          </MDBox>)
    }
  }
  if ([1,6].includes(cookies.get('role_type')) === true){
    colm.push(
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Title", accessor: "title", width: "0%", align: "center" },
      { Header: "Description", accessor: "description", width: "0%", align: "center" },
      { Header: "Price", accessor: "price", align: "center" },
      { Header: "Ticket Type", accessor: "ticket_type", align: "center" },
      { Header: "Service Charge", accessor: "service_charge", align: "center" },
      { Header: "Language", accessor: "language", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    )
  }
  else{
    colm.push(
      { Header: "S.No.", accessor: "srn", width: "0%", align: "center" },
      { Header: "Title", accessor: "title", width: "0%", align: "center" },
      { Header: "Description", accessor: "description", width: "0%", align: "center" },
      { Header: "Price", accessor: "price", align: "center" },
      { Header: "Ticket Type", accessor: "ticket_type", align: "center" },
      { Header: "Service Charge", accessor: "service_charge", align: "center" },
      { Header: "Language", accessor: "language", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
    )
  }
  var dict = []; // Create an empty array
  if (props.data) {
    const row_datas = props.data.map((data,index) =>
                
                  dict.push(
                        {
                          srn: <Author name={index+1}  />,
                          title: <Author name={data.title}  />,
                          description: <Author name={data.description}  />,
                          price: <Author name={data.price}  />,
                          ticket_type: <Author name={data.ticket_type}  />,
                          service_charge: <Author name={data.service_charge}  />,
                          language: <Author name={data.langauge}  />,
                          status: <Statusdata val={data.is_active}  />,
                          action: (
                            <MDTypography component="a" variant="caption" color="text" fontWeight="medium" onClick={(e) => callbackRequest(data.id)}>
                              Edit
                            </MDTypography>
                          )
                        }

                      )
                    )
                      }
                      
  return {
    columns:colm,
    rows: dict
  };
}
