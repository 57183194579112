/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState ,useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Paper, Typography, Container, makeStyles } from '@mui/material';

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import sort_top_logo from "assets/images/custom_images/sort_logo.jpg";
import sort_bottom_logo from "assets/images/custom_images/fourth_logo.png";
import Cookie from 'universal-cookie'
import axios from "axios";
import base_url from 'variables/api'
import ReportBasicLayout from "layouts/authentication/components/ReportBasicLayout";
import { useReactToPrint } from 'react-to-print';
import { useParams ,useSearchParams,useLocation} from "react-router-dom";
import { borderLeft } from "@mui/system";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

function SummaryPDFReport() {

  const [totalamt, setTotalamt] = useState("0");
  const [totaltickets, setTotaltickets] = useState("0");
  const [startdate, setStartdate] = useState("0");
  const [enddate, setEnddate] = useState("0");
  const [totaltable, setTotaltable] = useState([]);
  


  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const handlePrint = () =>{
  //   alert("Adsca");
  // }
  useEffect(() => {

    const response = axios({
        method: 'get',
        url:base_url+`/api/auth/v1/api/summary/report/${params['from']}/${params['to']}/${params['type']}/`,
        // headers : {'Authorization': 'Token '+cookies.get('token')}
      })
      .then(function (response) {
          if(response.data){
            console.log(response,'response')
            setEnddate(response.data['output_to_date'])
            setStartdate(response.data['output_from_date'])
            setTotaltable(response.data['dynamic_data'])
            setTotalamt(response.data['all_total_amts'])
            setTotaltickets(response.data['all_total_tickets'])
            // const [cookies, setCookie] = cookie(['access_token', 'refresh_token'])
            // let expires = new Date()
            // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
            // console.log(cookie.set('access_token', "response.data.access_token"))
            // cookies.set('role_type',response.data.roles , { path: '/'  ,maxAge: 14 * 60 * 60,
            // sameSite: true});
          }
  
    })
    const timeoutId = setTimeout(() => {
    handlePrint(); 
    }, 6000);
  }, []);
  const data1 = [
    { name: 'Category A', value: 30 },
    { name: 'Category B', value: 50 },
    { name: 'Category C', value: 20 },
  ];

const CustomLegend = () => (
  <div>
    {data1.map((entry, index) => (
      <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
        <div style={{ width: '10px', height: '10px', backgroundColor: entry.color }} />
        <span style={{ marginLeft: '5px' }}>{entry.name}</span>
      </div>
    ))}
  </div>
);
// const final_dict = 
// console.log(totaltable,'totaltable')
//   const final_all_data = () => {
//     totaltable.map((data,index) => (

//       ) 
//     )
//   }



  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} >
      <Card >
      
        <Grid container spacing={3} color="dark">
            <Grid item xs={12} md={12} lg={12} style={{color:"black"}} justifyContent='center' alignItems='center' textAlign='center'>
              <img 
                      width={400}
                      srcSet={sort_top_logo}
                      src={sort_top_logo}
                      alt="A"
                      loading="lazy"
                    />
            </Grid>
        </Grid>
        <Grid container spacing={3} color="dark">
          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} justifyContent='center' alignItems='center' textAlign='center'>
            <MDTypography variant="h1" fontWeight="bold" textTransform="capitalize" py={1} size="large" style={{fontSize : "30px"}}>
              NANDANKANAN ZOOLOGICAL PARK- BHUBANESHWAR , ODISHA
            </MDTypography>
            <MDTypography variant="h4" fontWeight="bold" textTransform="capitalize" py={1} size="large" >
              SUMMARY REPORT FOR THE PERIOD {startdate} TO {enddate}
            </MDTypography>
          </Grid>
        </Grid>
  
        <Grid container spacing={1} color="dark" justifyContent='center' alignItems='center' textAlign='center' p={4}>
          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} justifyContent='center' alignItems='center' textAlign='center'>
            <table border="1"  style={{border : "1px solid #dddddd",borderCollapse : 'collapse',width : '100%',borderColor : 'black'}}>
            <thead style={{border : "1px solid #dddddd",borderCollapse : 'collapse',width : '100%',backgroundColor : "lightgray",borderColor : 'black'}}>
              <tr key='1'>
                <th>SR.No</th>
                <th>TICKET TYPE</th>
                <th>NO. OF TICKETS </th>
                <th>AMOUNT (INR)</th>
                <th >SERVICE CHARGES
                  <tr>
                      <th style={{width : '33.33%',borderBottom :'none',borderLeft:'none'}}>Serive Providers Rs. 1.18</th>
                      <th style={{width : '33.33%',borderBottom :'none',}}>Dept. Rs. 0.32</th>
                      <th style={{width : '33.33%',borderBottom :'none',}}>TOTAL (INR)</th>
                  </tr>
                    {/* <table border="1" style={{backgroundColor : "lightgray",justifyContent : 'center',alignContent : 'center',textAlign : 'center',borderColor : 'black'}}>
                        <th >Serive Providers Rs. 1.18</th>
                        <th >Dept. Rs. 0.32</th>
                        <th >TOTAL (INR)</th>
                    </table> */}
                </th>
                <th>TOTAL AMOUNT (INR)</th>
              </tr>
            </thead>
            <tbody>
              {/* {final_all_data()} */}
              {totaltable.map((data,index) => (
                <tr key={index}>
                  <td>{data['sr']}</td>
                  <td>{data['ticket_type']}</td>
                  <td>{data['no_tickets']}</td>
                  <td>{data['amt']}</td>

                  <td>
                  <tr>
                      <th style={{minWidth:'177.4px',width : '33.33%',borderTop : 'none',borderBottom: 'none',borderLeft : 'none'}}>{data['ser_chrge'][0]}</th>
                      <th style={{width : '33.33%',borderTop : 'none',borderBottom: 'none',borderLeft : 'none'}}>{data['ser_chrge'][1]}</th>
                      <th style={{width : '33.33%',borderTop : 'none',borderBottom: 'none',borderLeft : 'none',borderRight : 'none'}}>{data['ser_chrge'][2]}</th>
                  </tr>
                    {/* <table border="1" style={{justifyContent : 'center',alignContent : 'center',textAlign : 'center',borderColor : 'black'}}>
                          <td ></td>
                          <td >{data['ser_chrge'][1]}</td>
                          <td >{data['ser_chrge'][2]}</td>
                    </table> */}
                  </td>
                  <td>{data['total_amt']}</td>
                </tr>
              ))
                
              }
              <tr>
                  <td>6</td>
                  <td colSpan = "3" ><b>TOTAL SALES AMOUNT </b></td>
                  <td style={{'backgroundColor' : "darkorange"}} colSpan="2">{totalamt}</td>
              </tr>
              <tr>
                  <td>7</td>
                  <td colSpan = "3"><b>TOTAL TICKETS </b></td>
                  <td style={{'backgroundColor' : "darkorange"}} colSpan="2">{totaltickets}</td>
              </tr>
            </tbody>
            </table>
          </Grid>
        </Grid>
        
        <Grid container spacing={1} color="dark">
          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} justifyContent='left' alignItems='left' textAlign='left' mt={5} ml={5}>
            <Typography variant="body2" align="left">
              Powered By               
            </Typography>
          </Grid>
            
          <Grid item xs={12} md={12} lg={12} style={{color:"black"}} justifyContent='left' alignItems='left' textAlign='left' mt={0} ml={5}>
            <img 
                    width={100}
                    srcSet={sort_bottom_logo}
                    src={sort_bottom_logo}
                    alt="A"
                    loading="lazy"
                  />
          </Grid>
          

        <Container maxWidth="lg" >

          <Typography variant="body2" align="center">
            {new Date().toString()} 
          </Typography>
          <Typography variant="body2" align="center">
            This is System generated report
          </Typography>
        </Container>
        </Grid>

      </Card>
    </div>
  ));


        return (
          <ReportBasicLayout image={''}>
            <Card>
              <button onClick={handlePrint}>Print all data</button>
              <ComponentToPrint ref={componentRef} />
            </Card>
          </ReportBasicLayout>
        );
      }
export default SummaryPDFReport;
